#root {
background-color: #b3dfeb;
padding: 1px;
}
.container {

  margin-top: 1px; /* Felül 10 pixel margó */
  margin-right: 5px; /* Jobb oldalon 10 pixel margó */
  margin-bottom: 1px; /* Alul 10 pixel margó */
  margin-left: 5px; /* Bal oldalon 10 pixel margó */
}

.container p {
  font-size: 16px;
  font-weight: 600;
}
.container input {
  font-size: 18px;
  font-weight: 600;
  
}
.App {
  text-align: center;

}
#lastt {
  padding-bottom: 1px;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.text-center img {
  max-height: 110px;
  margin: 0px;
  padding: 0px;
  
}
#lastt .only {
  font-size: 14px;
  padding-bottom: 1px;
  text-decoration: underline;
}

#fix {
  padding-bottom: 2px;
  border-bottom: 2px solid black;
}
#mission {
  padding-top: 1px;
  padding-bottom: 1px;
  font-style: italic;
  font-size: large;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mb-0 {
  margin-top: 1px; /* Hozzáadott margó felül */
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
